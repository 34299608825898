<template>
    <div
        ref="modal"
        class="modal fade background-darken"
        :class="{ 'in': opened, 'show': opened, 'd-block': opened }"
        @click.self="close()"
        @keyup.esc="close()"
    >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :class="sizeClass">
            <div class="modal-content">
                <div class="modal-header">
                    <slot name="header">
                        <slot name="title"></slot>
                        <button type="button" class="close" aria-label="Fermer" @click.stop="close()">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Fermer</span>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Modal',
        data() {
            return {
                lastKnownBodyStyle: {
                    overflow: 'auto',
                },
                opened: false,
                sizeMatrix: {
                    large: 'modal-lg',
                    small: 'modal-sm',
                    medium: 'modal-md',
                    xl: 'modal-xl',
                }
            }
        },
        props: {
            size: {
                default: 'medium',
            },
        },
        computed: {
            isOpened() {
                return this.opened;
            },
            isClosed() {
                return !this.opened;
            },
            sizeClass() {
                return this.sizeMatrix[this.size] || null;
            }
        },
        methods: {
            toggle() {
                if (this.opened) {
                    this.close();
                } else {
                    this.open();
                }

                this.$emit('toggled');
            },
            open() {
                this.opened = true;

                this.$nextTick(() => {
                    this.$refs.modal.focus();
                    this.lastKnownBodyStyle.overflow = document.body.style.overflow;
                    document.body.style.overflow = 'hidden'
                    this.$emit('opened');
                });
            },
            close() {
                this.opened = false;

                this.$nextTick(() => {
                    document.body.style.overflow = this.lastKnownBodyStyle.overflow;
                    this.$emit('closed');
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .background-darken {
        background: rgba(0, 0, 0, 0.3);
    }
</style>
