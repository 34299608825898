$(() => {
    // Job filter
    $('#FilterMore').on('click', function() {
        $('#FilterMoreBlock').toggleClass('d-flex');
        document.querySelectorAll('.filter-block').forEach(item=>item.classList.add('d-none'));
    });

    // Header nav mobile
    $('#AdminLink').on('click', function() {
        toggleCaret('AdminIcon', 'AdminBlock');
    });

    $('#QuickIntentLink').on('click', function() {
        toggleCaret('QuickIntentIcon', 'QuickIntentBlock');
    });

    $('#LegalNoticeLink').on('click', function() {
        toggleCaret('LegalNoticeIcon', 'LegalNoticeBlock');
    });

    $('#ParametersLink').on('click', function() {
        toggleCaret('ParametersIcon', 'ParametersBlock');
    });

    // Oe mobile
    $('#DescriptionJob').on('click', function() {
        $('#DescriptionJobBlock').toggleClass('d-none');
        $('#DescriptionJob i.fa-chevron-up').toggleClass('fa-chevron-down');
        $('#DescriptionJob i.fa-chevron-down').toggleClass('fa-chevron-up');
    });

    $('#MissionsJob').on('click', function() {
        $('#MissionsJobBlock').toggleClass('d-none');
        $('#MissionsJob i.fa-chevron-up').toggleClass('fa-chevron-down');
        $('#MissionsJob i.fa-chevron-down').toggleClass('fa-chevron-up');
    });

    $('#ProfileJob').on('click', function() {
        $('#ProfileJobBlock').toggleClass('d-none');
        $('#ProfileJob i.fa-chevron-up').toggleClass('fa-chevron-down');
        $('#ProfileJob i.fa-chevron-down').toggleClass('fa-chevron-up');
    });

    $('#AdditionalInformationsJob').on('click', function() {
        $('#AdditionalInformationsJobBlock').toggleClass('d-none');
        $('#AdditionalInformationsJob i.fa-chevron-up').toggleClass('fa-chevron-down');
        $('#AdditionalInformationsJob i.fa-chevron-down').toggleClass('fa-chevron-up');
    });

    // Applicant profile
     $('#EditAdvancedLink').on('click', function() {
        toggleChevron('EditAdvancedLink ~ i', 'EditAdvancedBlock');
    });

    // ApplicantFilter
    $('#FilterProfileLink').on('click', function() {
        toggleChevron('FilterProfileLink i', 'FilterProfileBlock');
    });

    $('#FilterAlertLink').on('click', function() {
        toggleChevron('FilterAlertLink i', 'FilterAlertBlock');
    });

    document.querySelectorAll('.navbarSupportedContentLink').forEach(link => {
        $(link).on('click', function() {
            $('#navbarSupportedContent').toggleClass('show');
        })
    })

    // AdminFilter
    $('#FilterChevron').on('click', function() {
        toggleChevron('FilterChevron i');
    });

    $('#Job').on('change', function(){
        $('.job-details').addClass('d-none');
        if (this.value) {
            $('#jobDetails-' + this.value).removeClass('d-none');
        }
    })
});

function toggleChevron(icon, idBlock) {
    $('#'+ icon).toggleClass('fa-chevron-down');
    $('#'+ icon).toggleClass('fa-chevron-up');

    if(idBlock) {
        $('#' + idBlock).toggleClass('d-none');
    }
}

function toggleCaret(id, idBlock) {
    $('#'+ id).toggleClass('fa-caret-down');
    $('#'+ id).toggleClass('fa-caret-up');

    if(idBlock) {
        $('#' + idBlock).toggleClass('d-none');
    }
}

