/**
 * Select2 initialization
 */

import 'select2';

$.fn.select2.defaults.set("theme", "bootstrap4");

$(() => {
    let defaultOptions = {
        language: {
            inputTooShort: function() {
                return 'Entrer un ou plusieurs caractères';
            },
            noResults: function () {
                return 'Pas de résultat';
            },
        },
        width: 'style',
        templateResult: function(option) {
            if(option.element && (option.element).hasAttribute('hidden')){
                return null;
            }
            return option.text;
        },
    };

    $('select.select2').select2(defaultOptions);

    $('select.ajax-select2').select2({
        ...defaultOptions,
        ajax: {
            delay: 500,
            url: function () {
                return $(this).data('url')
            },
            dataType: 'json',
            data: function (params) {
                return {
                    q: params.term,
                    page: params.page || 1,
                    perPage: 25,
                }
            },
            processResults: function (data, params) {
                return {
                    results: data.data,
                };
            },
        },
    });

    $('select.select2').on('select2:selecting', function (event) {
        if ($(event.params.args.data.element).hasClass('select-all')) {
            let $select = $(event.target);
            event.preventDefault();

            $select.val($select.find('option:not(.select-all)')
                .map(function (index, option) {
                    return $(option).val();
                })
            );
            $select.trigger('change');
            $select.select2('close');
        }
    });

    $('select[data-mode="parentToChild"]').on('change', function(e) {
        let $this = $(this),
            $target = $('#' + $(this).data('target-select')),
            $selected = $this.find(':selected');

        if ($target.find(':selected').length == 0) {
            if ($selected.val() == '') {
                $target.data('select2')
                    .$container
                    .find('.select2-search__field')
                    .attr(
                        'placeholder',
                        $target.data('undefined-placeholder') || 'Choisissez une catégorie'
                    );
            } else {
                $target.data('select2')
                    .$container
                    .find('.select2-search__field')
                    .attr(
                        'placeholder',
                        'Choisissez un poste de la catégorie ' + $selected.text()
                    );
            }
        }

        $target.find('option:not(:disabled)')
            .prop('disabled', true)
        ;

        if ($selected.val()) {
            $target.find('option[data-category=' + $selected.val() + ']')
                .prop('disabled', false)
            ;
        }
    }).change();

    $('select.select2[data-bound-select=organization-center-recruiter]')
        .on('change', function () {
            let $this = $(this),
                $target = $($this.data('bound-to')),
                $selected = $this.find(':selected'),
                boundValue = $selected.data('bound-value')
            ;

            $target.find('option:not(:disabled)')
                .prop('disabled', true)
            ;

            if ($selected.val()) {
                $target.find('option[data-binding-value=' + boundValue + ']')
                    .prop('disabled', false)
                ;
            }
        })
        .change()
    ;

    $('select.select2[data-bound-select=organization-hub-center]')
        .on('change', function () {
            let $this = $(this),
                $targets = $($this.data('bound-to')),
                $selected = $this.find(':selected'),
                boundValue = $selected.data('bound-value')
            ;

            $targets.find('option:not(:disabled)')
                .prop('disabled', true)
            ;

            if ($selected.val()) {
                $targets.find('option[data-binding-value=' + boundValue + ']')
                    .prop('disabled', false)
                ;
            }
        })
        .change()
    ;

    $('select.select2[data-bound-select=organization-center]')
        .on('change', function () {
            let $target = $($(this).data('bound-to')),
                $selected = $(this).find(':selected')
            ;

            if ($selected.val()) {
                $target.find('optgroup:not(:disabled)')
                    .prop('disabled', true)
                ;

                $target.find('option:not(:disabled)')
                    .prop('disabled', false)
                ;

                $selected.each((key, element) => {
                    let option = $target.find('option[data-binding-value=' + element.dataset.boundValue + ']')

                    option.prop('disabled', false);
                    option.parent().prop('disabled', false);
                });
            } else {
                $target.find('optgroup:disabled')
                    .prop('disabled', false)
                ;

                $target.find('option:disabled')
                    .prop('disabled', false)
                ;
            }
        })
        .change()
    ;

    $('#FilterLocation')
        .on('select2:select', function (e) {
            if (e.params.data.id.match(/^city:.*$/g)) {
                $('#FilterCityRange').addClass('d-block');
            } else {
                $('#FilterCityRange').removeClass('d-block');
            }

            $('#FilterHomeRange').addClass('d-none');
        }).on('select2:unselect', function () {
            $('#FilterHomeRange').removeClass('d-none');
            $('#FilterCityRange').removeClass('d-block');
        })
    ;
});
