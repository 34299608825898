<template>
    <div class="new-position-selection">
        <!-- element displaying the selected values -->
        <div class="form-group">
            <label v-if="!labelAsPlaceholder">
                <span v-html="fullLabelText"></span>
                <span title="Champ obligatoire" v-if="!required && labelClass != ''" :class="labelClass">*</span>
            </label>
            <div class="input-group">
                <div class="form-control" @click.self="toggleModal()" :class="{'is-invalid': errorString.length}">
                    <span class="text-muted" v-if="labelAsPlaceholder" v-show="!selectedPositions.length" v-html="fullLabelText"/>
                    <a
                        v-for="newPosition in selectedPositions"
                        @click.prevent="removeNewPosition(newPosition)"
                        class="badge badge-light"
                        href="#"
                        :title="newPosition.name"
                    >
                        <span
                            v-text="newPosition.name"
                            class="position-name"
                        />
                        <span>&times;</span>
                    </a>
                </div>
                <div class="invalid-feedback" v-text="errorString"></div>
            </div>

            <template v-if="multiple">
                <template v-for="id in selected">
                    <input v-if="formName" :form="formName" type="hidden" :name="formInputName" :value="id" :disabled="disabled">
                    <input v-else type="hidden" :name="formInputName" :value="id" :disabled="disabled">
                </template>
            </template>
            <template v-else>
                <input v-if="formName" :form="formName" type="hidden" :name="formInputName" :value="selected" :disabled="disabled">
                <input v-else type="hidden" :name="formInputName" :value="selected" :disabled="disabled">
            </template>
        </div>

        <modal ref="newPositionSelectorModal" :size="'large'" v-if="!disabled">
            <h4 slot="title" class="text-center" v-html="modalTitle"></h4>

            <div class="container">
                <div class="nav nav-tabs btn-group row">
                    <a
                        class="col-12 col-lg-4 btn btn-blue-calypso p-3 p-lg-1 nav-border"
                        v-for="positionCategory in positionCategories"
                        :class="{ 'active': currentPositionCategory.id && positionCategory.id == currentPositionCategory.id }"
                        @click.prevent="setCurrentPositionCategory(positionCategory)"
                        href="#"
                        v-text="positionCategory.name"
                    />
                </div>
                <div class="tab-content">
                    <div class="tab-pane active show">
                        <div
                            class="custom-control"
                            v-for="newPosition in currentPositionCategory.new_positions"
                            :class="{'custom-checkbox': multiple, 'custom-radio': !multiple}"
                        >
                            <input
                                v-if="multiple && formName"
                                type="checkbox"
                                class="custom-control-input"
                                :form="formName"
                                :id="'newPositionInput-' + id + newPosition.id"
                                :value="newPosition.id"
                                v-model="selected"
                                @change="selectedChanges"
                            >
                            <input
                                v-else-if="multiple && !formName"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'newPositionInput-' + id + newPosition.id"
                                :value="newPosition.id"
                                v-model="selected"
                                @change="selectedChanges"
                            >
                            <input
                                v-else-if="!multiple && formName"
                                type="radio"
                                class="custom-control-input"
                                :form="formName"
                                :id="'newPositionInput-' + id + newPosition.id"
                                :value="newPosition.id"
                                v-model="selected"
                                :checked="isChecked(newPosition)"
                                @change="selectedChanges"
                            >
                            <input
                                v-else-if="!multiple && !formName"
                                type="radio"
                                class="custom-control-input"
                                :id="'newPositionInput-' + id + newPosition.id"
                                :value="newPosition.id"
                                v-model="selected"
                                :checked="isChecked(newPosition)"
                                @change="selectedChanges"
                            >
                            <label
                                class="custom-control-label"
                                :for="'newPositionInput-' + id + newPosition.id"
                                v-text="newPosition.name"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div slot="footer" class="text-center text-sm-right">
                <button
                    type="button"
                    class="btn btn-dugong"
                    @click.prevent="toggleModal()"
                    v-text="closeButtonText"
                />
            </div>
        </modal>
    </div>
</template>

<script>
    import { PositionCategory, NewPosition } from '@models';
    import { reject } from 'lodash';
    import Modal from './Modal.vue';

    export default {
        name: 'NewPositionSelection',
        components: {
            Modal,
        },
        props: {
            value: {
                default() {
                    return null;
                }
            },
            closeButtonText: {
                type: String,
                default: 'Fermer',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            errorString: {
                type: String,
                default: '',
            },
            formName: {
                type: String,
                default: '',
            },
            initFormInputName: {
                type: String,
                default: 'newPosition',
            },
            positionCategories: {
                type: Array,
                default() {
                    return [];
                },
            },
            labelText: {
                type: String,
                default: 'Mes postes',
            },
            labelAsPlaceholder: {
                type: Boolean,
                default: false,
            },
            labelClass: {
                type: String,
                default: '',
            },
            modalTitle: {
                type: String,
                default: 'Sélectionner un ou plusieurs postes',
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                currentPositionCategory: this.positionCategories[0],
                selected: this.value ? this.value : (this.multiple ? [] : null),
                // VueJS component id to define a unique form input id in the page
                id: this._uid,
            };
        },
        computed: {
            formInputName() {
                if (!this.multiple) {
                    return this.initFormInputName;
                }

                return this.initFormInputName + '[]';
            },
            fullLabelText() {
                if (this.required) {
                    return `${this.labelText} *`;
                }

                return this.labelText;
            },
            positions() {
                return this.positionCategories.reduce(
                    (acc, positionCategory) => acc.concat(positionCategory.new_positions),
                    []
                )
            },
            selectedPositions() {
                if (this.multiple) {
                    return this.positions.filter((position) => this.selected.includes(position.id));
                }

                return [this.positions.find((position) => this.selected == position.id)].filter((val) => null != val);
            },
        },
        methods: {
            isChecked(newPosition) {
                return this.selected && this.selected.id === newPosition.id
            },
            removeNewPosition(newPosition) {
                if (this.disabled) {
                    return;
                }

                if (this.multiple) {
                    this.selected = reject(
                        this.selected,
                        (id) => id == newPosition.id
                    );
                } else {
                    this.selected = null;
                }

                this.selectedChanges();
            },
            selectedChanges() {
                this.$emit('update:value', this.selected);
            },
            setCurrentPositionCategory(positionCategory) {
                this.currentPositionCategory = positionCategory;
            },
            toggleModal() {
                if (!this.disabled) {
                    this.$refs.newPositionSelectorModal.toggle();
                }
            },
        }
    }
</script>

<style scoped lang="scss">
.new-position-selection {
    .nav-tabs {
        border-bottom: 0;

        .nav-border {
            @media (width <= 992px) {
                border: 2px solid white;
                border-radius:30px;
            }
        }
    }

    .input-group {
        .form-control {
        min-height: 37px;
        height: auto;
        overflow: hidden;

            .badge {
                margin-right: .5rem;
                font-weight: 200;
                font-size: .9rem;
                padding: 7px;
                margin: 3px;

                .new-position-name ~ span {
                    font-weight: 700;
                }
            }
        }
    }

    .tab-content {
        margin-top: 2rem;

        .custom-control {
            margin: 0.75rem 1rem;
            padding-bottom: 0.5rem;

            border-bottom: 0.75px solid #707070;
        }
    }
}

.custom-control-input:indeterminate {
    & ~ .custom-control-label::before {
        background-color: #FFF !important;
    }
}
</style>
