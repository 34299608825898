/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.$ = window.jQuery = jQuery;
window.Popper = Popper;

import axios from 'axios';
import 'bootstrap';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

/**
 * SENTRY INTEGRATION
 */
import * as Sentry from '@sentry/browser';
const appEnv = import.meta.env.VITE_APP_ENV;
const dsn = import.meta.env.VITE_SENTRY_LARAVEL_DSN;
const enableSentry = typeof appEnv === 'string' && (appEnv === 'production' || appEnv === 'staging');
if (typeof dsn === 'string' && enableSentry) {
    const commitID = (typeof import.meta.env.VITE_COMMIT_ID === 'string') ? import.meta.env.VITE_COMMIT_ID : null;
    Sentry.init({
        dsn: dsn,
        environment: appEnv,
        release: commitID,
    });
}

/**
 * ASSETS
 * These are assets that should be included in Vite's build and made available through Vite's manifest
 */
import.meta.glob([
    '/resources/images/**',
    '!/resources/images/demo/**',
    '!**.xcf',
    '!**.md',
]);
