$(() => {
    $('form').on('submit', function (event) {
        $(this).find(':submit:not(.btn-no-spinner)')
            .each(function (index, button) {
                $(button).addClass('disabled');
            })
        ;

        $(this).off('submit')
            .on('submit', function (event) {
                event.preventDefault();
            })
        ;

        if (event.originalEvent && event.originalEvent.submitter) {
            $(event.originalEvent.submitter).each((idx, el) => {
                if (!$(el).hasClass('btn-no-spinner')) {
                    $(el).html('<span class="spinner-border width--16 height--16"></span>')
                }
            });
        }
    });

    $('#MediaCoverLetter').on('change', function() {
        var inputFile = this;
        var submitBtn = $('#submitApply');
        var MessageMediaCoverLetterShow = $('#MediaCoverLetterShow');
        if (inputFile.files.length > 0) {
            submitBtn.removeAttr('disabled');
            submitBtn.removeClass('btn-dugong');
            submitBtn.addClass('btn-fuel-yellow');
        } else {
            submitBtn.attr('disabled', true);
            submitBtn.removeClass('btn-fuel-yellow');
            submitBtn.addClass('btn-dugong');
            MessageMediaCoverLetterShow.empty();
        }
    });
});
