$(() => {
    $('.js-show-password button').on('click', function(event) {
        event.preventDefault();

        var $input = $(this).parents('.js-show-password').find('input');
        var $i = $(this).parents('.js-show-password').find('i');

        if ($input.attr('type') == 'text') {
            $input.attr('type', 'password');
            $i.addClass('fa-eye-slash');
            $i.removeClass('fa-eye');
        } else if($input.attr('type') == 'password') {
            $input.attr('type', 'text');
            $i.removeClass('fa-eye-slash');
            $i.addClass('fa-eye');
        }
    });
});
